@font-face {
    font-family: 'Chobani Serif';
    src: url('../public/fonts/ChobaniSerif-Regular.eot'); /* IE9 Compat Modes */
    src: url('../public/fonts/ChobaniSerif-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../public/fonts/ChobaniSerif-Regular.woff2') format('woff2'),
        url('../public/fonts/ChobaniSerif-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Chobani Sans';
    src: url('../public/fonts/ChobaniSans-Regular.eot'); /* IE9 Compat Modes */
    src: url('../public/fonts/ChobaniSans-Regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../public/fonts/ChobaniSans-Regular.woff2') format('woff2'),
        url('../public/fonts/ChobaniSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Chobani Serif', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #1a3c34;
    letter-spacing: 0;
}

.App {
    min-height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;
}
